button.el-button {
  height: 34px;
  min-width: 60px;
  border-radius: 1px;
  color: white;
  font-size: 14px;
  background-color: #4494de;
  box-shadow: 1px 1px 0 0 #b1ddfd inset, 1px 1px 0 0 #b1ddfd inset, 1px 1px 0 0 #52a0f8, 1px 1px 0 0 #4494d9;
  border: 1px solid #2178d0;

  &.el-button-sub {
    color: #0969cb;
    background-color: #cae2fd;
    box-shadow: 1px 1px 0 0 #fdffff inset, 1px 1px 0 0 #fdffff inset, 1px 1px 0 0 #e2feff, 1px 1px 0 0 #aacff4;
    border: 1px solid #318ae3;
  }

  &.el-button-inner {
    height: 26px;
  }
}